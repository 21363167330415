<div class="image-capture-wrapper">
    <video #video muted playsinline></video>
    <div class="action-bg-bg-holder">
        <ul class="image-previews d-flex">
            <li *ngFor="let image of imagePreviewArray; let i = index;">
                <svg *ngIf="image.capturedImage === null; else showImagethumb" width="31" height="28"
                    viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.333 11.1485C12.8304 11.1485 10.8009 13.1775 10.8009 15.6807C10.8009 18.1838 12.8304 20.2123 15.333 20.2123C17.8356 20.2123 19.8652 18.1833 19.8652 15.6807C19.8652 13.1781 17.8356 11.1485 15.333 11.1485ZM28.1946 5.48317L22.6329 4.56659L21.8004 1.18069C21.6553 0.687487 21.3901 0.569641 20.8838 0.569641H9.78282C9.27652 0.569641 9.00264 0.69949 8.86624 1.18069L8.03368 4.56713L2.472 5.48371C1.2979 5.69376 0.333313 6.44121 0.333313 7.6224V25.2917C0.333313 26.4729 1.29027 27.4304 2.472 27.4304H28.1946C29.3764 27.4304 30.3333 26.4729 30.3333 25.2917V7.62185C30.3333 6.44067 29.3589 5.70249 28.1952 5.48317H28.1946ZM15.333 23.6238C10.946 23.6238 7.38934 20.0672 7.38934 15.6801C7.38934 11.2931 10.946 7.73643 15.333 7.73643C19.7201 7.73643 23.2767 11.2931 23.2767 15.6801C23.2767 20.0672 19.7201 23.6238 15.333 23.6238Z"
                        fill="#333338" />
                </svg>
                <ng-template #showImagethumb>
                    <div (click)="onRemoveCaptureImageByIndex(i)" class="holder">
                        <i class="fas fa-minus remove-img"></i>
                        <img src="{{ image.capturedImage }}" alt="" class="img-fluid">
                    </div>
                </ng-template>
            </li>
        </ul>
        <ul class="action-buttons justify-content-between align-items-center">
            <li style="background: none;" class="d-sm-none" style="opacity: 0; visibility: hidden;">
                <svg (click)="switchCamera()" width="35" height="30" viewBox="0 0 35 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.501 17.4773H13.7497C13.8847 17.4773 14.0165 17.4338 14.1247 17.3531C14.4011 17.1482 14.4569 16.7597 14.2495 16.4863L11.7499 13.1835C11.7145 13.1368 11.6722 13.0949 11.6245 13.0598C11.3486 12.8544 10.957 12.9101 10.7497 13.1835L8.24951 16.4863C8.16861 16.5935 8.12468 16.7241 8.12468 16.8579C8.12468 17.1997 8.40433 17.4773 8.74987 17.4773H10.0008C10.0008 18.7635 10.3356 20.0454 11.0047 21.1931C12.0317 22.9559 13.6442 24.1683 15.5717 24.6588C17.4713 25.1419 19.5381 24.8929 21.2498 23.9135C21.8476 23.5717 22.0523 22.8142 21.7073 22.2213C21.3623 21.6289 20.5978 21.4261 19.9999 21.768C18.8663 22.4161 17.4724 22.5844 16.1931 22.259C14.917 21.9346 13.8606 21.1405 13.1701 19.9541C12.7243 19.1892 12.501 18.3351 12.5015 17.4773H12.501ZM22.5837 16.2384H21.2498C21.1147 16.2384 20.983 16.2819 20.8748 16.3626C20.5983 16.5675 20.5426 16.9561 20.7499 17.2294L23.2496 20.5322C23.2849 20.5789 23.3273 20.6208 23.3749 20.6559C23.6508 20.8613 24.0424 20.8056 24.2498 20.5322L26.75 17.2294C26.8309 17.1222 26.8748 16.9916 26.8748 16.8579C26.8748 16.516 26.5951 16.2384 26.2496 16.2384H25.0839C25.0705 14.9814 24.7346 13.7318 24.0783 12.6054C23.0798 10.8914 21.4292 9.65832 19.5301 9.14448C17.6181 8.62693 15.5937 8.87801 13.8333 9.88551C13.2354 10.2274 13.0308 10.9848 13.3758 11.5772C13.7213 12.1696 14.4853 12.3724 15.0836 12.0306C16.2633 11.3559 17.6004 11.1897 18.8717 11.5342C20.1446 11.8787 21.2492 12.7042 21.9135 13.8438C22.3469 14.5881 22.5708 15.4098 22.5842 16.2384H22.5837ZM8.74987 5.09112L9.95416 2.36323C10.5515 1.0107 11.8999 0.136963 13.3897 0.136963H21.6098C23.0996 0.136963 24.448 1.01123 25.0453 2.36323L26.2496 5.09112H29.9996C32.7612 5.09112 35 7.30943 35 10.0458V24.9088C35 27.6452 32.7612 29.863 29.9996 29.863H5.00038C2.23876 29.863 0 27.6452 0 24.9088V10.0458C0 7.30943 2.23876 5.09112 5.00038 5.09112H8.74987Z"
                        fill="white" />
                </svg>
            </li>
            <li [ngClass]="{'disabled': totalImageCaptured === 5}">
                <button class="capture-photo" type="button">
                    <svg (click)="capturePhoto()" width="60" height="60" viewBox="0 0 60 60" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="30" cy="30" r="27.5" stroke="#20AF87" stroke-width="5" />
                        <circle cx="30" cy="30" r="15" fill="#20AF87" />
                    </svg>
                </button>
            </li>
            <li [ngClass]="{'disabled': totalImageCaptured === 0}">
                <svg (click)="onProceedToTagsComments()" width="31" height="30" viewBox="0 0 31 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.6667 0C11.6885 0 7.87312 1.58036 5.06008 4.39339C2.24704 7.20643 0.666687 11.0218 0.666687 15C0.666687 18.9782 2.24704 22.7936 5.06008 25.6066C7.87312 28.4196 11.6885 30 15.6667 30C18.2997 30 20.8862 29.3068 23.1667 27.9905C25.4472 26.6737 27.3404 24.7805 28.6572 22.5C29.9735 20.2195 30.6667 17.633 30.6667 15C30.6667 11.0218 29.0863 7.20643 26.2733 4.39339C23.4603 1.58036 19.6449 0 15.6667 0ZM23.1667 15.03C23.1715 15.3562 23.044 15.6702 22.8126 15.9L17.8744 20.8227C17.5695 21.128 17.1249 21.2475 16.7076 21.1366C16.2908 21.0252 15.9645 20.7 15.8526 20.2832C15.7406 19.8664 15.8595 19.4212 16.1644 19.1159L19.0562 16.2209H9.36615C8.93758 16.2209 8.54115 15.9921 8.32687 15.6209C8.11258 15.2496 8.11258 14.7921 8.32687 14.4209C8.54115 14.0496 8.93758 13.8209 9.36615 13.8209H19.0835L16.1644 10.9018C15.859 10.5964 15.7395 10.1512 15.8515 9.73393C15.9635 9.31661 16.2892 8.99036 16.7065 8.87893C17.1238 8.76696 17.569 8.88643 17.8744 9.19179L22.8126 14.115C23.0403 14.3411 23.1678 14.6491 23.1667 14.97V15.03Z"
                        fill="white" />
                </svg>
            </li>
        </ul>
    </div>
</div>

<app-mediaupload (indexOfImageRemove)="onImageRemovedFromUploadComponent($event)" [imageArrayToPush]="imageArrayToPush"
    [imagePreviewArray]="imagePreviewArray" [customerToken]="customerToken"
    [authKeyCoordinatesPayload]="authKeyCoordinatesPayload" [isTagsCommentVisible]="isTagsCommentVisible"
    (closeTag)="onCloseTag()"></app-mediaupload>