<div class="image-capture-wrapper" style="justify-content: flex-start; align-items: flex-start; padding-bottom: 0;"
    #videoContainer>
    <div class="timer" *ngIf="recordedVideoPreviewUrl === null">
        <p>{{ minutes }}:{{ seconds | number: '2.0' }}</p>
    </div>
    <video id="recordedPreviewVideo" style="position: fixed; z-index: 99999; width: 100%; height: calc(100% - 137px);"
        [src]="recordedVideoPreviewUrl" controls playsinline *ngIf="recordedVideoPreviewUrl !== null"></video>
    <video id="video_{{idx}}" class="video-js vjs-default-skin" playsinline></video>
    <div class="action-bg-bg-holder" style="background: rgba(51, 51, 56, 1); z-index: 99999; height: 100px;">
        <ul class="action-buttons justify-content-between align-items-center">
            <li style="opacity: 0; width: 25px;">
            </li>
            <li class="d-flex align-items-center">
                <button (click)="onStartRecording()" *ngIf="!isRecordingStarted" type="button" class="start-recording">
                    <span></span>
                </button>
                <button *ngIf="isRecordingStarted" (click)="onStopRecording()" class="stop-recording" type="button">
                    <span></span>
                </button>
            </li>
            <li [ngClass]="{'disabled': !isProceedToTagVisible}">
                <svg (click)="onProceedToTagsComments()" width="31" height="30" viewBox="0 0 31 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.6667 0C11.6885 0 7.87312 1.58036 5.06008 4.39339C2.24704 7.20643 0.666687 11.0218 0.666687 15C0.666687 18.9782 2.24704 22.7936 5.06008 25.6066C7.87312 28.4196 11.6885 30 15.6667 30C18.2997 30 20.8862 29.3068 23.1667 27.9905C25.4472 26.6737 27.3404 24.7805 28.6572 22.5C29.9735 20.2195 30.6667 17.633 30.6667 15C30.6667 11.0218 29.0863 7.20643 26.2733 4.39339C23.4603 1.58036 19.6449 0 15.6667 0ZM23.1667 15.03C23.1715 15.3562 23.044 15.6702 22.8126 15.9L17.8744 20.8227C17.5695 21.128 17.1249 21.2475 16.7076 21.1366C16.2908 21.0252 15.9645 20.7 15.8526 20.2832C15.7406 19.8664 15.8595 19.4212 16.1644 19.1159L19.0562 16.2209H9.36615C8.93758 16.2209 8.54115 15.9921 8.32687 15.6209C8.11258 15.2496 8.11258 14.7921 8.32687 14.4209C8.54115 14.0496 8.93758 13.8209 9.36615 13.8209H19.0835L16.1644 10.9018C15.859 10.5964 15.7395 10.1512 15.8515 9.73393C15.9635 9.31661 16.2892 8.99036 16.7065 8.87893C17.1238 8.76696 17.569 8.88643 17.8744 9.19179L22.8126 14.115C23.0403 14.3411 23.1678 14.6491 23.1667 14.97V15.03Z"
                        fill="white" />
                </svg>
            </li>
        </ul>
    </div>
</div>

<app-mediaupload [recordEndTime]="recordEndTime" [recordStartTime]="recordStartTime"
    [videoBlobDataToUpload]="blobDataToUpload" [customerToken]="customerToken"
    [authKeyCoordinatesPayload]="authKeyCoordinatesPayload" [isTagsCommentVisible]="isTagsCommentVisible"
    (closeTag)="onCloseTag()"></app-mediaupload>