import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as StackdriverErrorReporter from '@google-cloud/error-reporting';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// const errorHandler = new StackdriverErrorReporter();

// errorHandler.start({
//   key: 'AIzaSyB96PeArnc-j04sUhTwzLsXYBThdQCKC1E',
//   projectId: 'ionassist-integration',
//   reportUncaughtExceptions: true,
//   service: 'ia-ext-weblink',
//   version: '1.0' 
// });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
