import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthorisationInterceptor implements HttpInterceptor {
  constructor(
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiReq = req.clone({
      url: `${environment.baseUrl}${req.url}`,
    });
    if (
      !req.url.includes('googleapis.com') &&
      !req.url.includes('s3.eu-west-2.amazonaws.com')
    ) {
      // this.ngxService.start();
      return next.handle(apiReq).pipe(
        tap(
          (event) => {
            if (event instanceof HttpResponse) {
              // this.ngxService.stop();
            }
          },
          (error: HttpErrorResponse) => {
            this.ngxService.stop();
            throw error;
          }
        ),
        catchError((error: HttpErrorResponse) => {
          if (!error.error.is_success) {
            this.toastr.error(error.error.error);
          }
          return throwError(error);
        })
      );
    } else {
      // this.ngxService.stop();
      return next.handle(req);
    }
  }
}
