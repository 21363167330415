import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MediaService } from '../media.service';

// import * as $ from 'jquery';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mediaupload',
  templateUrl: './mediaupload.component.html',
  styleUrls: ['./mediaupload.component.scss'],
})
export class MediauploadComponent implements OnInit {
  @Input() isTagsCommentVisible = false;
  @Output() closeTag = new EventEmitter<boolean>();
  @Input() customerToken = null;
  @Input() authKeyCoordinatesPayload = null;
  public tagsCommentsForm: UntypedFormGroup;
  public allMainTags = [];
  public allSubTags = [];
  public allFilteredSubTags = [];
  @Input() imageArrayToPush = [];
  @Input() imagePreviewArray = [
    {
      capturedImage: null,
    },
    {
      capturedImage: null,
    },
    {
      capturedImage: null,
    },
    {
      capturedImage: null,
    },
    {
      capturedImage: null,
    },
  ];
  public idForGroupingImage = null;
  public totalImageUploaded = 0;
  public totalImageCaptured = 0;
  public isMainTagVisible = false;
  public isSubTagVisible = false;
  public selectedCategory = 'Product to be Returned';
  public selectedSubCategory = 'Reason for Return';
  teamData: any;
  public disableSubmitBtn = true;
  @Output() indexOfImageRemove = new EventEmitter<number>();
  @Input() videoBlobDataToUpload = null;
  @Input() recordStartTime = null;
  @Input() recordEndTime = null;

  constructor(
    private mediaSVC: MediaService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
    this.tagsCommentsForm = new UntypedFormGroup({
      main_tag: new UntypedFormControl(null, [
        Validators.nullValidator,
        Validators.required,
      ]),
      sub_tag: new UntypedFormControl(null, [
        Validators.nullValidator,
        Validators.required,
      ]),
      comment: new UntypedFormControl(''),
    });
    this.tagsCommentsForm.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.disableSubmitBtn = false;
      } else {
        this.disableSubmitBtn = true;
      }
    });
  }

  ngOnInit(): void {
    this.onProceedToTagsComments();
  }

  onProceedToTagsComments(): void {
    this.ngxService.start();
    this.mediaSVC
      .onGetTags(this.authKeyCoordinatesPayload['auth_key'], this.customerToken)
      .subscribe(
        (res: any) => {
          let tagData = res['data'];
          let { team, tags } = tagData;
          this.teamData = team;
          if (tags.length > 0) {
            
            let { tag_config } = team;
            if(tag_config){
              let { tag_title, tag_subtitme } = tag_config;
              this.selectedCategory = tag_title.display_en;
              this.selectedSubCategory = tag_subtitme.display_en;
            }
            this.allMainTags = tags.filter((tag) => !tag.parent_id);
            this.allSubTags = tags.filter((tag) => tag.parent_id);
          } else {
            this.toastr.error(
              'No tags found for the transaction id you have entered!'
            );
          }
          this.ngxService.stop();
        },
        (error) => this.ngxService.stop()
      );
  }

  onCloseTagCommentForm(): void {
    this.tagsCommentsForm.reset();
    this.isTagsCommentVisible = false;
  }

  onPopulateSubTags(value: string, mainTagName: string): void {
    this.isMainTagVisible = false;
    this.selectedCategory = mainTagName;
    let { tag_config } = this.teamData;
    this.selectedSubCategory = 'Reason for Return';
    if(tag_config){
      let { tag_subtitme } = tag_config;
      this.selectedSubCategory = tag_subtitme.display_en;
      this.selectedSubCategory = tag_subtitme.display_en;
    }
    
    this.tagsCommentsForm.patchValue({
      main_tag: value,
      sub_tag: null,
    });
    this.allFilteredSubTags = [];
    this.allFilteredSubTags = this.allSubTags.filter(
      (subtag) => subtag.parent_id === value
    );
  }

  onSelectSubTag(value: string, subTagName: string): void {
    this.isSubTagVisible = false;
    this.selectedSubCategory = subTagName;
    this.tagsCommentsForm.patchValue({
      sub_tag: value,
    });
  }

  onCloseCommentWrapper(): void {
    this.closeTag.emit(true);
  }

  onToggleCategoryDropdown(): void {
    this.isMainTagVisible = !this.isMainTagVisible;
  }

  onToggleSuCategoryDropdown(): void {
    this.isSubTagVisible = !this.isSubTagVisible;
  }

  onSubmitUpload(): void {
    this.onGetPresignedUrl(this.imageArrayToPush[0], 0);
  }

  onLoopImageUpload(): void {
    for (let index = 0; index < this.imageArrayToPush.length; index++) {
      this.onGetPresignedUrl(this.imageArrayToPush[index], index + 1);
    }
  }

  onGetPresignedUrl(blobData: any, imageIndex: number = 0): void {
    this.ngxService.start();
    if (this.videoBlobDataToUpload === null) {
      let imageIndexValue = imageIndex;
      let payload = {
        t: this.customerToken,
        auth_key: this.authKeyCoordinatesPayload['auth_key'],
        file_name: new Date().valueOf() + '.jpeg',
        content_type: 'application/jpeg',
        upload_type: 'image',
        source: 'web',
      };
      this.mediaSVC.onGetPresignedUrl(payload).subscribe((res: any) => {
        const fileData = new File([blobData], res['data']['file_name']);
        debugger;
        this.mediaSVC
          .onUploadImageToS3Bucket(fileData, res['data']['url'])
          .subscribe((s3Res) => {
            let formValue = JSON.parse(
              JSON.stringify(this.tagsCommentsForm.value)
            );
            let savePayload = {
              t: this.customerToken,
              auth_key: this.authKeyCoordinatesPayload['auth_key'],
              file_name: res['data']['file_name'],
              path: res['data']['path'],
              ...formValue,
            };
            if (this.authKeyCoordinatesPayload['coordinates']) {
              savePayload = {
                ...savePayload,
                coordinates: {
                  latitude:
                    this.authKeyCoordinatesPayload['coordinates']['latitude'],
                  longitude:
                    this.authKeyCoordinatesPayload['coordinates']['longitude'],
                },
              };
            }
            if (this.idForGroupingImage !== null) {
              savePayload['id'] = this.idForGroupingImage;
            }
            this.mediaSVC
              .onSaveMediaToIonSystem(savePayload)
              .subscribe((saveRes) => {
                if (this.idForGroupingImage === null) {
                  this.idForGroupingImage = saveRes['data']['id'];
                }
                this.totalImageUploaded++;
                if (imageIndexValue === 0) {
                  this.imageArrayToPush.splice(0, 1);
                  this.onLoopImageUpload();
                }
                if (
                  this.imagePreviewArray.filter(
                    (item) => item.capturedImage !== null
                  ).length === this.totalImageUploaded
                ) {
                  this.onCloseTagCommentForm();
                  this.ngxService.stop();
                  // this.router.navigate(['/thankyou']);
                  window.location.href = '/thankyou';
                }
              });
          });
      });
    } else {
      let payload = {
        t: this.customerToken,
        auth_key: this.authKeyCoordinatesPayload['auth_key'],
        file_name: new Date().valueOf() + '.mp4',
        content_type: 'video/mp4',
        upload_type: 'video',
        source: 'web',
      };
      this.mediaSVC.onGetPresignedUrl(payload).subscribe((res: any) => {
        const fileData = new File(
          [this.videoBlobDataToUpload],
          res['data']['file_name']
        );
        this.mediaSVC
          .onUploadImageToS3Bucket(fileData, res['data']['url'])
          .subscribe((s3Res) => {
            let savePayload = {
              t: this.customerToken,
              auth_key: this.authKeyCoordinatesPayload['auth_key'],
              file_name: res['data']['file_name'],
              path: res['data']['path'],
              record_start_time: this.recordStartTime,
              record_end_time: this.recordEndTime,
              ...this.tagsCommentsForm.value,
            };
            if (this.authKeyCoordinatesPayload['coordinates']) {
              savePayload = {
                ...savePayload,
                coordinates: {
                  latitude:
                    this.authKeyCoordinatesPayload['coordinates']['latitude'],
                  longitude:
                    this.authKeyCoordinatesPayload['coordinates']['longitude'],
                },
              };
            }
            this.mediaSVC
              .onSaveMediaToIonSystem(savePayload)
              .subscribe((saveRes) => {
                this.ngxService.stop();
                // this.router.navigate(['/thankyou']);
                window.location.href = '/thankyou';
              });
          });
      });
    }
  }

  onRemoveCaptureImageByIndex(index): void {
    this.totalImageCaptured = this.imageArrayToPush.length;
    this.imageArrayToPush.splice(index, 1);
    this.imagePreviewArray[index].capturedImage = null;
    this.imagePreviewArray.sort((a, b) => {
      if (a.capturedImage !== null) {
        return -1;
      } else {
        return 1;
      }
    });
    this.totalImageCaptured--;
    this.indexOfImageRemove.emit(this.totalImageCaptured);
    if (this.totalImageCaptured === 0) {
      this.isTagsCommentVisible = false;
      this.closeTag.emit(false);
    }
  }
}
