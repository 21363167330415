import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
// import * as $ from 'jquery';
import { BlockService } from 'src/app/block.service';

@Component({
  selector: 'app-imagecapture',
  templateUrl: './imagecapture.component.html',
  styleUrls: ['./imagecapture.component.scss'],
})
export class ImagecaptureComponent implements OnInit, OnDestroy {
  @Input() customerToken = null;
  @Input() authKeyCoordinatesPayload = null;
  public blobDataToUpload = null;
  public imageArrayToPush = [];
  public imagePreviewArray = [
    {
      capturedImage: null,
    },
    {
      capturedImage: null,
    },
    {
      capturedImage: null,
    },
    {
      capturedImage: null,
    },
    {
      capturedImage: null,
    },
  ];
  public idForGroupingImage = null;
  public totalImageUploaded = 0;
  public totalImageCaptured = 0;
  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvasElement: ElementRef;
  public videoStream: MediaStream;
  public facingMode = null;
  public zoom = 1;
  public isTagsCommentVisible = false;

  constructor(
    public blockService:BlockService 
  ) {}

  ngOnInit(): void {
    // if (window.innerWidth <= 767) {
    //   this.startCamera();
    // }
    this.startCamera();
  }

  onProceedToTagsComments(): void {
    this.isTagsCommentVisible = true;
  }

  onCloseTag(): void {
    this.isTagsCommentVisible = false;
  }

  async startCamera() {
    try {
      this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
      this.videoStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
        },
      });
      // videoElement = document.querySelector('video');
      this.videoElement.nativeElement.srcObject = this.videoStream;
      this.videoElement.nativeElement.play();
      // $('#permissionAlertBox').removeClass('d-block');
      this.blockService.setModalStatus(false);
    } catch (err) {
      this.blockService.setModalStatus(true);
      // $('#permissionAlertBox').addClass('d-block');
      console.error('Unable to access camera', err);
    }
  }

  capturePhoto() {
    const audio = new Audio('/assets/audio/camera-shutter.mp3');
    audio.play();
    const canvas = document.createElement('canvas');
    const videoElement = document.querySelector('video');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    canvas
      .getContext('2d')
      .drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    this.imagePreviewArray[this.totalImageCaptured].capturedImage =
      canvas.toDataURL();
    this.totalImageCaptured++;
    canvas.toBlob(
      (blob) => {
        this.imageArrayToPush.push(blob);
      },
      'image/jpeg',
      1
    );
  }

  switchCamera() {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: this.facingMode,
          deviceId: undefined,
        },
      })
      .then((stream) => {
        this.videoStream = stream;
        this.videoElement.nativeElement.srcObject = stream;
      })
      .catch((err) => {
        console.error('Error switching camera:', err);
      });
  }

  onRemoveCaptureImageByIndex(index): void {
    this.imageArrayToPush.splice(index, 1);
    this.imagePreviewArray[index].capturedImage = null;
    this.imagePreviewArray.sort((a, b) => {
      if (a.capturedImage !== null) {
        return -1;
      } else {
        return 1;
      }
    });
    this.totalImageCaptured--;
  }

  onImageRemovedFromUploadComponent(event): void {
    this.totalImageCaptured = event;
  }

  ngOnDestroy(): void {
    if (this.videoStream) {
      this.videoElement.nativeElement.pause();
      this.videoElement.nativeElement.src = '';
      this.videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }
}
