import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss'],
})
export class ThankyouComponent implements OnInit {
  public favIcon: HTMLLinkElement = document.querySelector('#dynamicFavicon');
  public brandLogo: HTMLImageElement = document.querySelector('#themeLogo');

  constructor(
    private meta: Meta
  ) {}

  ngOnInit(): void {
    const themeData = JSON.parse(sessionStorage.getItem('mytheme'));
    this.favIcon.href = themeData['fav_icon_path'];
    this.brandLogo.src = themeData['logo_path'];
    document.documentElement.style.setProperty(
      '--primaryColor',
      `${themeData['color_palatte'].primary}`
    );
    document.documentElement.style.setProperty(
      '--headerColor',
      `${themeData['color_palatte'].web_header_color}`
    );
    this.meta.updateTag({name:"theme-color",content:`${themeData['color_palatte'].web_header_color}`});

    document.documentElement.style.setProperty(
      '--tableRowColor',
      `${themeData['color_palatte'].table_row_color}`
    );
    document.documentElement.style.setProperty(
      '--webHeaderTextColor',
      `${themeData['color_palatte'].web_header_text_color}`
    );
  }
}
