import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AuthorisationInterceptor } from './shared/interceptor';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database/';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ImagecaptureComponent } from './routes/imagecapture/imagecapture.component';
import { WebcamModule } from 'ngx-webcam';
import { VideocaptureComponent } from './routes/videocapture/videocapture.component';
import { CallmanagementComponent } from './routes/callmanagement/callmanagement.component';
import { MediauploadComponent } from './shared/mediaupload/mediaupload.component';
import { ThankyouComponent } from './routes/thankyou/thankyou.component';

@NgModule({
  declarations: [AppComponent, ImagecaptureComponent, VideocaptureComponent, CallmanagementComponent, MediauploadComponent, ThankyouComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    NgxUiLoaderModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    WebcamModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorisationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
