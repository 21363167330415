import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import videojs from 'video.js';
import * as RecordRTC from 'recordrtc';

import * as Record from 'videojs-record/dist/videojs.record.js';
import { MediaService } from 'src/app/shared/media.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

// import * as $ from 'jquery';
import { BlockService } from 'src/app/block.service';

@Component({
  selector: 'app-videocapture',
  templateUrl: './videocapture.component.html',
  styleUrls: ['./videocapture.component.scss'],
})
export class VideocaptureComponent implements OnInit {
  @Input() customerToken = null;
  @Input() authKeyCoordinatesPayload = null;
  public isTagsCommentVisible = false;
  public allMainTags = [];
  public allSubTags = [];
  public allFilteredSubTags = [];
  public tagsCommentsForm: UntypedFormGroup;
  public recordStartTime = null;
  public recordEndTime = null;
  public blobDataToUpload = null;
  public isProceedToTagVisible = false;
  public isRecordingStarted = false;
  public isCameraShutterOpen = false;
  @ViewChild('videoContainer', { static: true }) videoContainer: ElementRef;
  public isStartedRecordedPlayback = false;
  public canShowRecPlayBtn = false;
  minutes: number = 0;
  seconds: number = 0;
  intervalId: any;
  public recordedVideoPreviewUrl = null;

  // reference to the element itself: used to access events and methods
  private _elementRef: ElementRef;

  // index to create unique ID for component
  idx = 'clip1';

  private config: any;
  private player: any;
  private plugin: any;

  constructor(
    elementRef: ElementRef,
    private mediaSVC: MediaService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    public blockService:BlockService 
  ) {
    this.tagsCommentsForm = new UntypedFormGroup({
      main_tag: new UntypedFormControl(null, Validators.required),
      sub_tag: new UntypedFormControl(null, Validators.required),
      comment: new UntypedFormControl(''),
    });
    this.player = false;
    // save reference to plugin (so it initializes)
    this.plugin = Record;
    // video.js configuration
    this.config = {
      controls: false,
      autoplay: false,
      fluid: false,
      loop: false,
      width: 640,
      height: 480,
      bigPlayButton: false,
      controlBar: {
        volumePanel: false,
      },
      plugins: {
        // configure videojs-record plugin
        record: {
          audio: true,
          video: {
            facingMode: 'environment',
          },
          debug: false,
          maxLength: 180,
        },
      },
    };
  }

  ngOnInit(): void {}

  // use ngAfterViewInit to make sure we initialize the videojs element
  // after the component template itself has been rendered
  async ngAfterViewInit() {
    // if (window.innerWidth <= 767) {
      // ID with which to access the template's video element
      let el = 'video_' + this.idx;

      // setup the player via the unique element ID
      this.player = videojs(document.getElementById(el), this.config, () => {
        console.log('player ready! id:', el);

        // print version information at startup
        // var msg =
        //   'Using video.js ' +
        //   videojs.getPluginVersion() +
        //   ' with videojs-record ' +
        //   videojs.getPluginVersion('record') +
        //   ' and recordrtc ' +
        //   RecordRTC.version;
        // videojs.log(msg);
      });

      // device is ready
      this.player.on('deviceReady', () => {
        console.log('deviceready');
      });
      this.player.record().getDevice();

      // user clicked the record button and started recording
      this.player.on('startRecord', () => {
        console.log('started recording!');
        this.isRecordingStarted = true;
        this.recordStartTime = new Date().toISOString();
        // this.toastr.info('Video recording started');
        this.startTimer();
      });

      // user completed recording and stream is available
      this.player.on('finishRecord', () => {
        // recordedData is a blob object containing the recorded data that
        // can be downloaded by the user, stored on server etc.
        this.blobDataToUpload = this.player.recordedData;
        this.recordedVideoPreviewUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(this.blobDataToUpload)
          );
        this.recordEndTime = new Date().toISOString();
        this.isRecordingStarted = false;
        // this.toastr.success('Video recorded successfully');
        this.isProceedToTagVisible = true;
        this.canShowRecPlayBtn = true;
        this.resetTimer();
      });

      // error handling
      this.player.on('error', (element, error) => {
        console.warn(error);
      });

      this.player.on('deviceError', () => {
        console.error('device error:', this.player.deviceErrorCode);
        this.blockService.setModalStatus(true);
        // $('#permissionAlertBox').addClass('d-block');
      });
    // }
  }

  // onCameraShutterOpen(): void {
  //   this.player.record().getDevice();
  //   this.isCameraShutterOpen = true;
  // }

  onStartRecording() {
    this.recordedVideoPreviewUrl = null;
    this.player.record().start();
  }

  onStopRecording() {
    this.player.record().stop();
  }

  onProceedToTagsComments(): void {
    this.isTagsCommentVisible = true;
  }

  onSubmitUpload(): void {
    this.onGetPresignedUrl(this.blobDataToUpload);
  }

  onGetPresignedUrl(blobData: any): void {
    let payload = {
      t: this.customerToken,
      auth_key: this.authKeyCoordinatesPayload['auth_key'],
      file_name: new Date().valueOf() + '.mp4',
      content_type: 'video/mp4',
      upload_type: 'video',
      source: 'web',
    };
    this.mediaSVC.onGetPresignedUrl(payload).subscribe((res: any) => {
      const fileData = new File([blobData], res['data']['file_name']);
      this.mediaSVC
        .onUploadImageToS3Bucket(fileData, res['data']['url'])
        .subscribe((s3Res) => {
          const savePayload = {
            t: this.customerToken,
            auth_key: this.authKeyCoordinatesPayload['auth_key'],
            file_name: res['data']['file_name'],
            path: res['data']['path'],
            coordinates: {
              latitude:
                this.authKeyCoordinatesPayload['coordinates']['latitude'],
              longitude:
                this.authKeyCoordinatesPayload['coordinates']['longitude'],
            },
            record_start_time: this.recordStartTime,
            record_end_time: this.recordEndTime,
            ...this.tagsCommentsForm.value,
          };
          this.mediaSVC
            .onSaveMediaToIonSystem(savePayload)
            .subscribe((saveRes) => {
              this.toastr.success('Video uploaded successfully!');
            });
        });
    });
  }

  onCloseTag(): void {
    this.isTagsCommentVisible = false;
  }

  startTimer() {
    this.intervalId = setInterval(() => {
      this.seconds++;
      if (this.seconds === 60) {
        this.seconds = 0;
        this.minutes++;
      }
    }, 1000);
  }

  resetTimer() {
    clearInterval(this.intervalId);
    this.minutes = 0;
    this.seconds = 0;
  }

  // use ngOnDestroy to detach event handlers and remove the player
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = false;
    }
    clearInterval(this.intervalId);
  }
}
