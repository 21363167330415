import { Component, Input, OnDestroy, OnInit,  OnChanges, SimpleChanges, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WebcallService } from 'src/app/shared/webcall.service';
import { environment } from 'src/environments/environment';
import { Howl } from 'howler';

import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
import { BlockService } from 'src/app/block.service';

declare let AgoraRTC: any;

@Component({
  selector: 'app-callmanagement',
  templateUrl: './callmanagement.component.html',
  styleUrls: ['./callmanagement.component.scss'],
})
export class CallmanagementComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  public agorAppId = environment.agorAppId;
  public agoraRTC = {
    client: null,
    localAudioTrack: null,
    localVideoTrack: null,
  };
  public agoraOptions = {
    appId: this.agorAppId,
    channel: null,
    token: null,
  };
  public callerId = null;
  public muteUnmuteAudio = false;
  public muteUnmuteVideo = false;
  public activeFirebaseId = null;
  public showCallStatus = false;
  public showCallNotAttended = false;
  public showActionButtons = false;
  public showChatWrapper = false;
  public chatData: any;
  public chatForm: UntypedFormGroup;
  public hideDisconnectBtn = false;
  public muteUnmuteAudioLocalAudio = false;
  public subsessionId = null;
  public showCallWaitingStatusMessage = true;
  public isUserAlertVisible = false;
  public isActionButtonsVisible = false; 
  public ActiveBlock=false;
  public howlAudio = new Howl({
    src: ['/assets/audio/calling-tune.mp3'],
    loop: true,
  });
  locationPollingTimeout = null;
  
  @Input() customerToken = null;
  @Input() oneToManyPayload = null;

  constructor(
    private webCallSVC: WebcallService,
    private fireRealTimeDB: AngularFireDatabase,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private el: ElementRef,
    public blockService:BlockService
  ) {}

  ngOnDestroy(): void {
    this.clearLocationPolling();
    this.howlAudio.Destroy();
  }

  ngOnInit(): void {
    if (this.customerToken !== null && this.oneToManyPayload !== null) {
      this.howlAudio.play(); 
      this.agoraRTC.client = AgoraRTC.createClient({
        mode: 'rtc',
        codec: 'vp8',
      });
      this.subscribeStreams();
      this.onGetSessionId();
      
    }
    
        this.chatForm = new UntypedFormGroup({
      msgTxt: new UntypedFormControl('', Validators.required),
    });
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showActionButtons']) {
      this.isActionButtonsVisible = this.showActionButtons;
    }
  }

  ngAfterViewInit(): void {
    // Change the height of the elements with the class 'my-class' after the view initializes
    const elements = this.el.nativeElement.querySelectorAll('.out-going-call-wrapper');
    const windowHeight = window.innerHeight + 'px';

    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'height', windowHeight);
    });
  }

  onGetSessionId(): void {
    this.webCallSVC
      .onGetSessionId(this.customerToken, this.oneToManyPayload)
      .subscribe((res: any) => {
        console.log(res, "resresresres");
        
        this.callerId = res['data']['requested_user_id'];
        this.agoraOptions.channel = res['data']['session_id'];
        this.activeFirebaseId = res['data']['firebase_id'];
        this.subsessionId = res['data']['sub_session_id'];
        this.fireRealTimeDB
          .list(
            '/quikstep-root/sessions_conversation/' + this.agoraOptions.channel
          )
          .valueChanges()
          .subscribe((chatRes) => {
            this.chatData = Object.keys(chatRes).map((key) => chatRes[key]);
            console.log(this.chatData);
            for (let i = 0; i < this.chatData.length; i++) {
              if (!this.chatData[i]['author_role']) {
                if ('en' !== this.chatData[i]['language_code']) {
                  this.webCallSVC
                    .onTranslateChat(
                      this.chatData[i]['payload'],
                      this.chatData[i]['language_code'],
                      'en'
                    )
                    .subscribe((translatedRes) => {
                      this.chatData[i]['payload'] =
                        translatedRes['data']['translations'][0][
                          'translatedText'
                        ];
                    });
                }
              }
            }
            if (this.chatData.length > 0) {
              this.showChatWrapper = true;
            }
          });
        this.fireRealTimeDB
          .list(
            `/quikstep-root/one_to_many/${res['data']['client_id']}/${res['data']['team_id']}/${this.activeFirebaseId}`
          )
          .snapshotChanges()
          .subscribe((nodeChange) => {
            let x = nodeChange.find((x) => x.key == 'status');
            let status;
            if (!x) {
              console.log('Something went wrong');
            } else {
              status = nodeChange.find((x) => x.key == 'status').payload.val();
              if (status === 'DISCONNECTED') {
                this.showChatWrapper = false;
                this.showActionButtons = false;
                this.isUserAlertVisible = false;
                this.leaveCall();
              }
              if (status === 'WAITING') {
                this.showCallWaitingStatusMessage = true;
              }
              if (status === 'ACTIVE') {
                this.showCallWaitingStatusMessage = false;
                this.isUserAlertVisible = true;
                this.showActionButtons = true;
              }
            }
          });
        this.startCall(res['data']['main_video_uuid']);
      });
  }

  public subscribeStreams() {
    this.agoraRTC.client.on('user-published', async (user, mediaType) => {
      this.howlAudio.stop(); 
      this.showActionButtons = true;
      this.isActionButtonsVisible=true;
      await this.agoraRTC.client.subscribe(user, mediaType);
      this.initLocationPolling();
      if (mediaType === 'audio') {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
      if (this.muteUnmuteAudio) {
        if (!user._audio_muted_) {
          this.muteUnmuteAudio = false;
          this.toastr.info('Other user unmuted audio', 'Audio Unmuted');
          return;
        }
      }
    });
    this.agoraRTC.client.on('user-unpublished', (user) => {
      if (!this.muteUnmuteAudio) {
        if (user._audio_muted_) {
          this.muteUnmuteAudio = true;
          this.toastr.info('Other user muted audio', 'Audio Muted');
          return;
        }
      }
    });
    this.agoraRTC.client.on('user-left', (user) => {

      this.onDisconnectCall();
    });
  }

  initLocationPolling(){
    this.locationPolling();
    if(this.locationPollingTimeout){
      this.clearLocationPolling();
    }
    this.locationPollingTimeout = setInterval(() => {
      this.locationPolling();
    }, 10000)
  }

  clearLocationPolling(){
    if(this.locationPollingTimeout){
      clearInterval(this.locationPollingTimeout);
    }
  }

  async locationPolling(){
    let position: any = await this.getLocation();
      
    if(position){
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;

      let payload = {
        coordinates: { latitude, longitude }
      }

      if(latitude && longitude){
        this.webCallSVC.updateLocation(this.agoraOptions.channel, payload).subscribe((resp => {
          console.log(resp);
        }))
      }
    }
  }

  async leaveCall() {
    this.howlAudio.stop();
    // Destroy the local audio tracks.
    this.agoraRTC.localAudioTrack.close();
    this.agoraRTC.localVideoTrack.close();
    this.hideDisconnectBtn = true;
    // Traverse all remote users.
    this.agoraRTC.client.remoteUsers.forEach((user) => {
      // Destroy the dynamically created DIV container.
      const playerContainer = document.getElementById(user.uid);
      playerContainer && playerContainer.remove();
    });
    // Leave the channel.
    await this.agoraRTC.client.leave();
    this.showChatWrapper = false;
    // this.showCallStatus = true;
    this.showCallNotAttended=true;
    
  }

  async startCall(mainVideoUUID: string) {
    
    
    await this.agoraRTC.client.join(
      this.agorAppId,
      this.agoraOptions.channel,
      this.agoraOptions.token,
      mainVideoUUID
    );
    
    
    let cameraId = null;
    let isLaptop = false;
    await AgoraRTC.getCameras()
      .then((camera) => {
        camera.map((item) => {
          if (
            item.label.includes('Webcam') ||
            item.label.includes('FaceTime')
          ) {
            cameraId = item['deviceId'];
            isLaptop = true;
            return;
          } else {
            cameraId = item['deviceId'];
          }
        });
      })
      .catch((error) => {
        if (error.code === 'PERMISSION_DENIED') {
          this.howlAudio.stop();
          this.onDisconnectCall();
          this.blockService.setModalStatus(true);
          // $('#permissionAlertBox').addClass('d-block');
        }
      });
    this.agoraRTC.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    this.agoraRTC.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
      facingMode: 'environment',
    });
    // this.agoraRTC.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
    //   cameraId: cameraId,
    // });
    await this.agoraRTC.client.publish([
      this.agoraRTC.localAudioTrack,
      this.agoraRTC.localVideoTrack,
    ]);
    const localPlayerContainer = document.createElement('div');
    localPlayerContainer.id = this.callerId;
    localPlayerContainer.style.width = '100%';
    localPlayerContainer.style.height = '100%';
    localPlayerContainer.style.transform = 'scaleX(-1)';
    document.getElementById('callHolder').append(localPlayerContainer);
    this.agoraRTC.localVideoTrack.play(localPlayerContainer);
    isLaptop = await this.detectDeviceType();
    console.log(`isLaptopisLaptop:::${isLaptop}`);
    this.agoraRTC.localVideoTrack.play(localPlayerContainer, {
      mirror: isLaptop,
      fit: isLaptop ? 'contain' : 'cover',
    });
  }
  async detectDeviceType(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const screenWidth = window.innerWidth;
    const isLaptop = screenWidth > 1024; 
    resolve(isLaptop);
  });
}

  async onDisconnectCall() {
    this.clearLocationPolling();
    this.webCallSVC
      .onDisconnectCall(
        this.agoraOptions.channel,
        this.customerToken,
        this.subsessionId
      )
      .subscribe((res) => {
        this.agoraRTC.localAudioTrack.close();
        this.agoraRTC.localVideoTrack.close();
        document.getElementById(this.callerId).remove();
        this.showChatWrapper = false;
        this.showCallStatus = true;
        this.howlAudio.stop();
        this.showActionButtons = false;
        this.hideDisconnectBtn = true;
        this.agoraRTC.client.leave();
      });
  }

  onMuteUnmuteAudio() {
    this.muteUnmuteAudioLocalAudio = !this.muteUnmuteAudioLocalAudio;
    if (!this.muteUnmuteAudioLocalAudio) {
      this.agoraRTC.localAudioTrack.setVolume(100);
      this.agoraRTC.localAudioTrack.setEnabled(true);
      this.toastr.info('Microphone has been Enabled.');
    } else {
      this.agoraRTC.localAudioTrack.setVolume(0);
      this.agoraRTC.localAudioTrack.setEnabled(false);
      this.toastr.info('Microphone has been Disabled.');
    }
  }

  onMuteUnmuteVideo() {
    this.muteUnmuteVideo = !this.muteUnmuteVideo;
    if (!this.muteUnmuteVideo) {
      this.agoraRTC.localVideoTrack.setEnabled(true);
      this.toastr.info('Camera has been Enabled.');
    } else {
      this.agoraRTC.localVideoTrack.setEnabled(false);
      this.toastr.info('Camera has been Disabled.');
    }
  }

  onSubmitChat() {
    if (this.chatForm.value['msgTxt'].length > 0) {
      this.fireRealTimeDB
        .list(
          '/quikstep-root/sessions_conversation/' + this.agoraOptions.channel
        )
        .push({
          author: this.callerId,
          author_name: 'Anonymous User',
          author_role: 'reserved',
          created_on: moment().utc().valueOf(),
          message_type: 'text',
          payload: this.chatForm.value['msgTxt'],
          language_code: 'en',
        });
      this.chatForm.reset();
    }
  }

  onShowChatWrapper() {
    this.showChatWrapper = !this.showChatWrapper;
  }

  getLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: any) => {
            if (position) {
              resolve(position);
            }else{
              resolve(null);
            }
          },
          (error: GeolocationPositionError) => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    })
  }
}
