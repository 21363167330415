import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebcallService } from './shared/webcall.service';
import { BlockService } from "./block.service"
// import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public brandLogo = './assets/img/main-logo.png';
  public authKeyCoordinatesObj = {
    auth_key: null,
    coordinates: {
      latitude: '0',
      longitude: '0',
    },
  };
  public operationType = null;
  public customerToken = null;
  public favIcon: HTMLLinkElement = document.querySelector('#dynamicFavicon');
  public isFirstPopupTriggered = false;
  public routerSubscription: Subscription;
  public Activedblock:boolean=false;
  public deviceDetails:string="";

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private webCallSVC: WebcallService,
    private router: Router,
    private meta: Meta,
    public blockService:BlockService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['auth_key']) {
        this.authKeyCoordinatesObj.auth_key = params['auth_key'];
        this.customerToken = params['t'];
        if (this.customerToken !== null) {
          this.getLocation();
          this.onGetThemeInfo();
        }
      } else {
        this.authKeyCoordinatesObj.auth_key = null;
        this.customerToken = null;
        this.operationType = null;
      }
    });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('isFirstPopupTriggered') === 'true') {
      this.isFirstPopupTriggered = true;
    }
    console.log("test1",this.Activedblock);
    this.blockService.modalOpen.subscribe((data)=>{
      this.Activedblock=data;
    });  
    console.log("test2",this.Activedblock);
    this.platFormDetection();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            this.authKeyCoordinatesObj.coordinates.latitude =
              position.coords.latitude;
            this.authKeyCoordinatesObj.coordinates.longitude =
              position.coords.longitude;
            // this.onGetThemeInfo();
            this.blockService.setModalStatus(false);
            // $('#permissionAlertBox').removeClass('d-block');
          }
        },
        (error: GeolocationPositionError) => {
          delete this.authKeyCoordinatesObj.coordinates;
          // this.blockService.setModalStatus(true);
          // $('#permissionAlertBox').addClass('d-block');
        }
      );
    } else {
      this.toastr.error('Geolocation is not supported by this browser.');
    }
  }
  

  onGetThemeInfo(): void {
    this.webCallSVC.onGetThemeInfo(this.customerToken).subscribe((res: any) => {
      sessionStorage.setItem('mytheme', JSON.stringify(res.data.theme_data));
      this.favIcon.href = res.data.theme_data.fav_icon_path;
      this.brandLogo = res.data.theme_data.logo_path;
      document.documentElement.style.setProperty(
        '--primaryColor',
        `${res.data.theme_data.color_palatte.primary}`
      );
      document.documentElement.style.setProperty(
        '--headerColor',
        `${res.data.theme_data.color_palatte.web_header_color}`
      );
      this.meta.updateTag({name:"theme-color",content:`${res.data.theme_data.color_palatte.web_header_color}`});
      document.documentElement.style.setProperty(
        '--tableRowColor',
        `${res.data.theme_data.color_palatte.table_row_color}`
      );
      document.documentElement.style.setProperty(
        '--webHeaderTextColor',
        `${res.data.theme_data.color_palatte.web_header_text_color}`
      );
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params['type']) {
          this.operationType = params['type'];
        }
      });
    });
  }

  onProceedToImageVideoCall(): void {
    this.isFirstPopupTriggered = true;
    sessionStorage.setItem('isFirstPopupTriggered', 'true');
  }

  platFormDetection():void{
    const userAgent = navigator.userAgent;
    console.log(userAgent);
    if (userAgent.includes('CriOS') && userAgent.includes('iPhone')  ) {
      this.deviceDetails="Iphone&Chrome";
    } else if (userAgent.includes('Chrome') && userAgent.includes('Android')) {
      this.deviceDetails="Android&Chrome";
    } else if (userAgent.includes('Chrome')) {
      this.deviceDetails="Chrome";
    } else if (userAgent.includes('Firefox')) {
      window.location.href = 'about:preferences#privacy';
    } else if (userAgent.includes('Edg')) {
      window.location.href = 'edge://settings/content';
    } else if (userAgent.includes('Safari') && userAgent.includes('iPhone') ) {
      this.deviceDetails="Iphone&Safari";
    } else if (userAgent.includes('Safari') && userAgent.includes('Macintosh')) {
      this.deviceDetails="Macintosh&Safari";
    } else if (userAgent.includes('Safari')) {
      this.deviceDetails="Safari";
    } else {
      this.deviceDetails="Default";
    }
    console.log(this.deviceDetails);
  }
}
