import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class BlockService {
  private modalStatus = new BehaviorSubject<boolean>(true);
  modalOpen = this.modalStatus.asObservable();
  constructor() { }

  setModalStatus(u){
    this.modalStatus.next(u);
  }
}
