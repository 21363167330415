<div class="tags-comment-wrapper" [ngClass]="{'show-tags-comment-wrapper': isTagsCommentVisible}">
    <div class="form-holder">
        <form [formGroup]="tagsCommentsForm" (ngSubmit)="onSubmitUpload()">
            <div class="content-holder d-flex flex-column justify-content-between">
                <div class="form-elements">
                    <div class="ion-form-group">
                        <div class="dropdown-holder" [ngClass]="{'show-dropdown': isMainTagVisible}">
                            <div (click)="onToggleCategoryDropdown()"
                                class="label-icon-holder d-flex justify-content-between align-items-center">
                                <p class="m-b-0">{{ selectedCategory }}</p>
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.0002 11.67C9.64494 11.67 9.30422 11.5288 9.05309 11.2775L0.392296 2.61669C0.141172 2.36557 0 2.02482 0 1.66958C0 1.31435 0.141187 0.973594 0.392296 0.722358C0.643531 0.471235 0.984283 0.330062 1.33952 0.330062C1.69479 0.330062 2.03551 0.471249 2.28663 0.722358L9.99998 8.43571L17.7133 0.722358C18.0517 0.384064 18.5449 0.251803 19.0071 0.375692C19.4694 0.499578 19.8304 0.860648 19.9543 1.32292C20.0782 1.7851 19.946 2.27831 19.6077 2.61669L10.9469 11.2775C10.6957 11.5288 10.355 11.67 9.99975 11.67H10.0002Z"
                                        fill="#20AF87" />
                                </svg>
                            </div>
                            <ul class="values">
                                <li (click)="onPopulateSubTags(mainTag['_id'], mainTag.display_en)"
                                    *ngFor="let mainTag of allMainTags">{{
                                    mainTag.display_en }}</li>
                            </ul>
                        </div>
                        <div class="dropdown-holder" [ngClass]="{'show-dropdown': isSubTagVisible}">
                            <div (click)="onToggleSuCategoryDropdown()"
                                class="label-icon-holder d-flex justify-content-between align-items-center">
                                <p class="m-b-0">{{ selectedSubCategory }}</p>
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.0002 11.67C9.64494 11.67 9.30422 11.5288 9.05309 11.2775L0.392296 2.61669C0.141172 2.36557 0 2.02482 0 1.66958C0 1.31435 0.141187 0.973594 0.392296 0.722358C0.643531 0.471235 0.984283 0.330062 1.33952 0.330062C1.69479 0.330062 2.03551 0.471249 2.28663 0.722358L9.99998 8.43571L17.7133 0.722358C18.0517 0.384064 18.5449 0.251803 19.0071 0.375692C19.4694 0.499578 19.8304 0.860648 19.9543 1.32292C20.0782 1.7851 19.946 2.27831 19.6077 2.61669L10.9469 11.2775C10.6957 11.5288 10.355 11.67 9.99975 11.67H10.0002Z"
                                        fill="#20AF87" />
                                </svg>
                            </div>
                            <ul class="values">
                                <li (click)="onSelectSubTag(subTag['_id'], subTag.display_en)"
                                    *ngFor="let subTag of allFilteredSubTags">{{
                                    subTag.display_en }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="ion-form-group">
                        <textarea formControlName="comment" placeholder="Your comments"></textarea>
                    </div>
                    <ul class="image-previews d-flex" *ngIf="videoBlobDataToUpload === null">
                        <li *ngFor="let image of imagePreviewArray; let i = index;">
                            <svg *ngIf="image.capturedImage === null; else showImagethumb" width="31" height="28"
                                viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.333 11.1485C12.8304 11.1485 10.8009 13.1775 10.8009 15.6807C10.8009 18.1838 12.8304 20.2123 15.333 20.2123C17.8356 20.2123 19.8652 18.1833 19.8652 15.6807C19.8652 13.1781 17.8356 11.1485 15.333 11.1485ZM28.1946 5.48317L22.6329 4.56659L21.8004 1.18069C21.6553 0.687487 21.3901 0.569641 20.8838 0.569641H9.78282C9.27652 0.569641 9.00264 0.69949 8.86624 1.18069L8.03368 4.56713L2.472 5.48371C1.2979 5.69376 0.333313 6.44121 0.333313 7.6224V25.2917C0.333313 26.4729 1.29027 27.4304 2.472 27.4304H28.1946C29.3764 27.4304 30.3333 26.4729 30.3333 25.2917V7.62185C30.3333 6.44067 29.3589 5.70249 28.1952 5.48317H28.1946ZM15.333 23.6238C10.946 23.6238 7.38934 20.0672 7.38934 15.6801C7.38934 11.2931 10.946 7.73643 15.333 7.73643C19.7201 7.73643 23.2767 11.2931 23.2767 15.6801C23.2767 20.0672 19.7201 23.6238 15.333 23.6238Z"
                                    fill="#333338" />
                            </svg>
                            <ng-template #showImagethumb>
                                <div (click)="onRemoveCaptureImageByIndex(i)" class="holder">
                                    <i class="fas fa-minus remove-img"></i>
                                    <img src="{{ image.capturedImage }}" alt="" class="img-fluid">
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>
                <div class="d-flex justify-content-between">
                    <button class="ion-theme-btn secondary" type="button"
                        (click)="onCloseCommentWrapper()">Cancel</button>
                    <button class="ion-theme-btn primary" type="submit" [disabled]="disableSubmitBtn">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>