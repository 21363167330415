import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebcallService {
  constructor(private httpClient: HttpClient) {}

  onGetThemeInfo(token: string): Observable<object> {
    return this.httpClient.get(`client/theme?t=${token}`);
  }

  onGetSessionId(token: string, payload: object): Observable<object> {
    return this.httpClient.post(`session/anonymous?t=${token}`, payload);
  }

  updateLocation(token: string, payload: object): Observable<object> {
    return this.httpClient.post(`session/location/s/${token}`, payload);
  }

  onDisconnectCall(
    receivedId: string,
    token: string,
    subSessionId: string
  ): any {
    if (receivedId != null) {
      return this.httpClient.post(
        `session/disconnect/session_id/${receivedId}?sub_id=${subSessionId}&t=${token}`,
        ''
      );
    }
  }

  onTranslateChat(
    textToTranslate: string,
    sourceLang: string,
    targetLang: string
  ) {
    return this.httpClient.get(
      'https://translation.googleapis.com/language/translate/v2/?q=' +
        textToTranslate +
        '&source=' +
        sourceLang +
        '&target=' +
        targetLang +
        '&key=' +
        environment.googleAPIMapKey
    );
  }
}
