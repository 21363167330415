<div class="thanks-message-wrapper">
    <svg width="129" height="128" viewBox="0 0 129 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_14_586)">
            <path
                d="M64.5 128C99.8462 128 128.5 99.3462 128.5 64C128.5 28.6538 99.8462 0 64.5 0C29.1538 0 0.5 28.6538 0.5 64C0.5 99.3462 29.1538 128 64.5 128Z"
                fill="#20AF87" />
            <path d="M126.7 27.4L35.8999 61.9L38.1999 91.5L126.7 27.4Z" fill="white" />
            <path d="M55.5 79.8L37.5 91.8L45.3 70L55.5 79.8Z" fill="#D4D6D6" />
            <path
                d="M38.2 92.5C37.9 92.5 37.6 92.4 37.4 92.1C37.1 91.7 37.2 91 37.6 90.7L126.1 26.6C126.5 26.3 127.2 26.4 127.5 26.8C127.8 27.2 127.7 27.9 127.3 28.2L38.7 92.3C38.6 92.5 38.4 92.5 38.2 92.5Z"
                fill="#293948" />
            <path d="M14.7 43.5L126.7 27.4L66.9 88.5L14.7 43.5Z" fill="white" />
            <path d="M121.8 29.4L35.8 63L37.5 92.4L46.2001 69.4L121.8 29.4Z" fill="#EAEAEA" />
            <path
                d="M66.9 89.5C66.7 89.5 66.3999 89.4 66.1999 89.3L44.4 70.5C44 70.1 43.9 69.5 44.2999 69.1C44.6999 68.7 45.3 68.6 45.7 69L66.8 87.2L123.8 28.9L17 44.2L37.4 61.8C37.8 62.2 37.9 62.8 37.5 63.2C37.1 63.6 36.4999 63.7 36.0999 63.3L14.1 44.3C13.8 44 13.7 43.6 13.8 43.3C13.9 42.9 14.2 42.6 14.6 42.6L126.5 26.5C126.9 26.4 127.3 26.7 127.5 27C127.7 27.4 127.6 27.8 127.3 28.2L67.5999 89.2C67.3999 89.4 67.2 89.5 66.9 89.5Z"
                fill="#293948" />
            <path
                d="M38.2001 92.5H37.9001C37.4001 92.3 37.1001 91.8 37.2001 91.2L44.2001 68.8C44.3001 68.5 44.5001 68.3 44.7001 68.2L108.4 35.7L38.0001 63.2C37.5001 63.4 36.9001 63.1 36.7001 62.6C36.5001 62.1 36.8001 61.5 37.3001 61.3L126.3 26.5C126.8 26.3 127.4 26.5 127.6 27C127.8 27.5 127.6 28.1 127.1 28.3L45.9001 69.8L39.1001 91.8C39.0001 92.3 38.6001 92.5 38.2001 92.5Z"
                fill="#293948" />
            <path
                d="M38.1999 92.5C37.6999 92.5 37.1999 92.1 37.1999 91.6L34.8999 61.9C34.8999 61.3 35.2999 60.9 35.7999 60.8C36.3999 60.8 36.7999 61.2 36.8999 61.7L39.1999 91.4C39.1999 92 38.7999 92.5 38.1999 92.5Z"
                fill="#293948" />
            <path opacity="0.5"
                d="M77.3999 39.1C76.8999 39.1 76.4999 38.7 76.3999 38.3C76.2999 37.8 76.6999 37.2 77.1999 37.2L90.4999 35.1C90.9999 35 91.5999 35.4 91.5999 35.9C91.6999 36.4 91.2999 37 90.7999 37L77.4999 39.1H77.3999Z"
                fill="#0C508E" />
            <path opacity="0.5"
                d="M25.7 47.3C25.2 47.3 24.8 46.9 24.7 46.5C24.6 46 25 45.4 25.5 45.4L65.2 39.1C65.7 39 66.3 39.4 66.3 39.9C66.4 40.4 66 41 65.5 41L25.8 47.3C25.9 47.3 25.8 47.3 25.7 47.3Z"
                fill="#0C508E" />
            <path opacity="0.5"
                d="M67.5 82C67.2 82 67 81.9 66.8 81.7C66.4 81.3 66.4 80.7 66.8 80.3L94.8 53.3C95.2 52.9 95.8 52.9 96.2 53.3C96.6 53.7 96.6 54.3 96.2 54.7L68.2 81.7C68 81.9 67.7 82 67.5 82Z"
                fill="#0C508E" />
            <path
                d="M17.5 79.2C15.3 79.2 13.5 77.4 13.5 75.2C13.5 73 15.3 71.2 17.5 71.2C19.7 71.2 21.5 73 21.5 75.2C21.5 77.4 19.7 79.2 17.5 79.2ZM17.5 73.1C16.3 73.1 15.4 74.1 15.4 75.2C15.4 76.4 16.4 77.3 17.5 77.3C18.7 77.3 19.6 76.3 19.6 75.2C19.6 74 18.7 73.1 17.5 73.1Z"
                fill="white" />
            <path
                d="M84.5 106.2C82.3 106.2 80.5 104.4 80.5 102.2C80.5 100 82.3 98.2 84.5 98.2C86.7 98.2 88.5 100 88.5 102.2C88.5 104.4 86.7 106.2 84.5 106.2ZM84.5 100.1C83.3 100.1 82.4 101.1 82.4 102.2C82.4 103.4 83.4 104.3 84.5 104.3C85.7 104.3 86.6 103.3 86.6 102.2C86.6 101 85.7 100.1 84.5 100.1Z"
                fill="white" />
            <path
                d="M88.5 25.2C86.3 25.2 84.5 23.4 84.5 21.2C84.5 19 86.3 17.2 88.5 17.2C90.7 17.2 92.5 19 92.5 21.2C92.5 23.4 90.7 25.2 88.5 25.2ZM88.5 19.1C87.3 19.1 86.4 20.1 86.4 21.2C86.4 22.4 87.4 23.3 88.5 23.3C89.7 23.3 90.6 22.3 90.6 21.2C90.6 20 89.7 19.1 88.5 19.1Z"
                fill="white" />
            <path
                d="M60.2999 21.4C60.2999 21 59.8999 20.6 59.2999 20.5L57.3999 20.2C56.8999 20.1 56.2999 19.6 56.2999 19.1L55.9999 17.2C55.8999 16.7 55.4999 16.2 55.0999 16.2C54.6999 16.2 54.2999 16.6 54.1999 17.2L53.8999 19.1C53.7999 19.6 53.2999 20.2 52.7999 20.2L50.8999 20.5C50.3999 20.6 49.8999 21 49.8999 21.4C49.8999 21.8 50.2999 22.2 50.8999 22.3L52.7999 22.6C53.2999 22.7 53.8999 23.2 53.8999 23.7L54.1999 25.6C54.2999 26.1 54.6999 26.6 55.0999 26.6C55.4999 26.6 55.8999 26.2 55.8999 25.6L56.1999 23.5C56.2999 23 56.7999 22.5 57.2999 22.4L59.0999 22.3C59.7999 22.2 60.2999 21.8 60.2999 21.4Z"
                fill="white" />
            <path
                d="M109 77C109 76.6 108.6 76.2 108 76.1L106.1 75.8C105.6 75.7 105 75.2 105 74.7L104.7 72.8C104.6 72.3 104.2 71.8 103.8 71.8C103.4 71.8 103 72.2 102.9 72.8L102.6 74.7C102.5 75.2 102 75.8 101.5 75.8L99.6001 76.1C99.1001 76.2 98.6001 76.6 98.6001 77C98.6001 77.4 99.0001 77.8 99.6001 77.9L101.5 78.2C102 78.3 102.6 78.8 102.6 79.3L102.9 81.2C103 81.7 103.4 82.2 103.8 82.2C104.2 82.2 104.6 81.8 104.6 81.2L104.9 79.1C105 78.6 105.5 78.1 106 78L107.8 77.9C108.6 77.7 109 77.4 109 77Z"
                fill="white" />
            <path
                d="M39.5 107.2C39.5 108.3 41.2 109.2 42.8 109.2H56.7C58.4 109.2 59.4 108.3 59.4 107.2C59.4 106.1 61 105.2 62.4 105.2C63.8 105.2 64.4 104.3 64.4 103.2C64.4 102.1 63.4 101.2 61.7 101.2H51.5C49.8 101.2 48.5 102.1 48.5 103.2C48.5 104.3 47.5 105.2 45.8 105.2H42.8C41.2 105.2 39.5 106.1 39.5 107.2Z"
                fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_14_586">
                <rect width="128" height="128" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
    <p class="text-center">
        Thank you for your request. We'll update you soon. To resubmit media, re-click the link/scan QR in email.
    </p>
</div>