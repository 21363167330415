// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'http://192.168.1.14:2383/api/',
  firebase: {
    apiKey: 'AIzaSyB96PeArnc-j04sUhTwzLsXYBThdQCKC1E',
    authDomain: 'ionassist-integration.firebaseapp.com',
    databaseURL: 'https://ionassist-integration.firebaseio.com',
    projectId: 'ionassist-integration',
    storageBucket: 'ionassist-integration.appspot.com',
    messagingSenderId: '212064931299',
    appId: '1:212064931299:web:2675cea1a905f949'
  },
  googleAPIMapKey: 'AIzaSyBb6CjueSbTa52x29-mkKgMSqeyEd8dqOE',
  agorAppId: '2ea336f50f064d63909bd0b699ddbcb8',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
