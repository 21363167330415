import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private httpClient: HttpClient) {}

  onGetPresignedUrl(payload: object): Observable<object> {
    return this.httpClient.post(`media/upload/preSignedUrl`, payload);
  }

  onUploadImageToS3Bucket(formData: object, s3url: string): Observable<object> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.httpClient.put(s3url, formData, { headers });
  }

  onSaveMediaToIonSystem(payload: object): Observable<object> {
    return this.httpClient.post(
      `${payload['record_start_time'] ? 'video' : 'image'}/anonymous`,
      payload
    );
  }

  onGetTags(auth_key: string, token: string): Observable<object> {
    return this.httpClient.get(`tag/auth_key/${auth_key}?t=${token}`);
  }
}
