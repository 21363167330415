<p class="notify-user" *ngIf="isUserAlertVisible">
    Clicking on back or refresh will disconnect the call
</p>

<section class="out-going-call-wrapper">
    <p class="call-status" *ngIf="showCallStatus">Call got disconnected, if you want to connect again then please reload the page</p>
    <p class="call-status" *ngIf="showCallNotAttended && !showCallStatus">Our team is currently busy. Kindly refresh the page after a few minutes to reinitiate the call.</p>
    <p class="call-status" *ngIf="showCallWaitingStatusMessage && !showCallStatus && !showCallNotAttended">Starting a video call. Calling the support team....</p>
    <div class="call-holder">
        <ul class="action-buttons">
            <li *ngIf="!hideDisconnectBtn" [class.disabled]="hideDisconnectBtn">
                <i class="fas fa-phone-alt" (click)="onDisconnectCall()" [class.disabled]="hideDisconnectBtn"></i>
            </li>
            <ng-container *ngIf="showActionButtons">
                <li *ngIf="!muteUnmuteAudioLocalAudio; else showUnmute" [class.disabled]="!isActionButtonsVisible">
                    <i (click)="onMuteUnmuteAudio()" class="fas fa-microphone" [class.disabled]="!isActionButtonsVisible"></i>
                </li>
                <ng-template #showUnmute>
                    <li [class.disabled]="!isActionButtonsVisible">
                        <i (click)="onMuteUnmuteAudio()" class="fas fa-microphone-slash" [class.disabled]="!isActionButtonsVisible"></i>
                    </li>
                </ng-template>
                <li *ngIf="!muteUnmuteVideo; else showUnmuteVideo" [class.disabled]="!isActionButtonsVisible">
                    <i (click)="onMuteUnmuteVideo()" class="fas fa-video" [class.disabled]="!isActionButtonsVisible"></i>
                </li>
                <ng-template #showUnmuteVideo>
                    <li [class.disabled]="!isActionButtonsVisible">
                        <i (click)="onMuteUnmuteVideo()" class="fas fa-video-slash" [class.disabled]="!isActionButtonsVisible"></i>
                    </li>
                </ng-template>
                <li [class.disabled]="!isActionButtonsVisible">
                    <i class="fas fa-comment-dots" (click)="onShowChatWrapper()" [class.disabled]="!isActionButtonsVisible"></i>
                </li>
            </ng-container>
        </ul>
        <div style="width: 100%; height: 100%;" id="callHolder" [ngClass]="{'d-none': showCallWaitingStatusMessage}">
        </div>
    </div>
</section>

<div [ngClass]="{'show-chat-wrapper': showChatWrapper}" class="chat-message-wrapper">
    <i class="far fa-times-circle close-chat" (click)="showChatWrapper = false;"></i>
    <div #chatMessageContainer id="textchat">
        <div id="history">
            <ng-container *ngFor="let chat of chatData">
                <div class="item d-flex flex-column" [ngClass]="{'their': !chat?.author_role}">
                    <p *ngIf="chat.message_type == 'text'; else showImage">{{ chat.payload }}</p>
                    <span class="align-self-end" style="font-weight: 700;">{{ chat.author_name }}</span>
                </div>
                <ng-template #showImage>
                    <img src="{{ chat.payload }}" class="image-fluid" alt=""
                        style="margin: auto; margin-right: 45px; width: 80%; display: block;">
                </ng-template>
            </ng-container>
        </div>
    </div>
    <form [formGroup]="chatForm" (ngSubmit)="onSubmitChat()">
        <img src="./assets/img/send-chat.png" class="send" alt="" style="cursor: pointer;" (click)="onSubmitChat()">
        <input type="text" placeholder="Enter your message here" class="form-control" formControlName="msgTxt" />
        <button class="btn btn-primary submit-chat" type="submit">Submit</button>
    </form>
</div>